<template>

  <div style="display: flex;">

    <div style="width: 30%;">

      <div style="display: flex;">
        <input type="text" v-model="searchStr">
        <button v-on:click="onClickSearch()">Search</button>
      </div>
      <div class="ral" style="padding: 0.5rem; cursor: pointer;" v-for="ral in rals" v-on:click="showLines(ral.id)" v-bind:key="ral.id">
        {{ ral.name }}
      </div>

    </div>

    <div style="width: 70%;">

      <div>
        Recipe {{ curRal.name }}
      </div>
      <div style="padding: 0.5rem;" v-for="recipe_line in recipe_lines" v-bind:key="recipe_line.id">
        <div style="display: flex; width: 80%; justify-content: space-between;">

          <div>{{ recipe_line.mix_name }}</div>
          <div>{{ recipe_line.volume / 1000 }}</div>
          <div>{{ recipe_line.amount / 1000 }}</div>
          
           
        </div>
      </div>

    </div>

  </div>

</template>

<script>
// @ is an alias to /src
import connections from '@/store/modules/connections';


export default {
  name: 'HomeView',
  components: {
    
  },

  data(){

    return {

      rals: [],
      curRal: { id: '', name: '' },
      recipe_lines: [],
      searchStr: ''

    }

  },

  mounted(){

    const data = { name: 'rals', fields: [ 'id', 'name' ], order: [ 'name' ] }

    this.fetchReq(data, res => {

      if (res.error) {
          
          console.log(res.error + res.message)

        } else {
         
          this.rals = res.result
          
        }

     

    })




  },

  methods: {

    onClickSearch(){

      const data = { 
        name: 'rals', 
        fields: [ 'id', 'name' ], 
        order: [ 'name' ], 
        filter: [ 'name like :searchstr' ],
        params: { searchstr: '%' + this.searchStr + '%' }
        }

        this.fetchReq(data, res => {

          if (res.error) {
              
              console.log(res.error + res.message)

            } else {
            
              this.rals = res.result
              
            }

        

        })



    },

    fetchReq(data, callback){

      fetch( connections.addr + 'read.php', { method: 'POST', headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        }, body: JSON.stringify(data)
      })
      .then(response => { 
      
        response.text()
        .then((text) => {

          try {
            
            const result = JSON.parse(text)

            callback( { error: false, result: result } );

          } catch (err) {
            
            callback( { error: err, message: text } );

          }


        })
        .catch((err) => {
        
          callback( { error: err } );

        })
      
      } )
      .catch((err) => {
        
          callback( { error: err } );

      })




    },

    showLines(ral_id){

      this.curRal = this.rals.filter(r => r.id == ral_id)[0]

      this.recipe_lines = []

      const recipe = { name: 'recipes', fields: [ 'id' ], 
        filter: [ 'ral_id = :ral_id' ],
        params: { ral_id: ral_id }
      }

      this.fetchReq(recipe, res => {

        if (res.error) {
          
          console.log(res.error + res.message)

        } else {
         
          if (res.result.length) {
            
            const data = { name: 'recipe_lines', fields: [ 'id', 'doc_id', 'line_number', 'mix_type', 'mix_id', 'volume' ], 
              order: [ 'line_number' ],
              filter: [ 'doc_id = :doc_id' ],
              params: { doc_id: res.result[0].id },
              joins: [
                {
                  fields: [ 'case when recipe_lines.mix_type = 0 then bases.name else mixes.name end as mix_name' ],
                  table: 'left join bases on bases.id = recipe_lines.mix_id left join mixes on mixes.id = recipe_lines.mix_id'
                },
              ]
            }

            this.fetchReq(data, res => {

              if (res.error) {
                
                console.log(res.error + res.message)

              } else {
              
                this.recipe_lines = res.result

                let amount = 0

                this.recipe_lines.forEach(r => {

                  amount = amount + Number(r.volume)

                  r.amount = amount

                })
                
              }

            })

           
          }

        }

      })


    }

  }

}
</script>

<style scoped>

.ral:hover {

  background-color: grey;

}

</style>